import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import { InvoicesPrint } from 'ui/component/orders-list/order-print';
import {
    ISalesLayoutConfig,
    salesLayoutConfigVar,
    currentOrderVar,
    invoiceIdVar,
} from '../../layouts/sales-layout/sales-layout-state';
import { salesGuestValidOrderResultVar } from '../../layouts/guest-layout/guest-layout-state';
import { SalesLayout } from '../../layouts/sales-layout';
import { OrderDetailsPageContent } from '../order-details-page-content';
import { findInvoiceById } from '../../util/process-order-entities';
import orderDetailsStyles from '../style.css';

const InvoicePrintPage: React.FC = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [pageTitle, setPageTitle] = useState<string>('');

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const salesGuestValidOrderResult = useReactiveVar(salesGuestValidOrderResultVar);
    const currentOrder = useReactiveVar(currentOrderVar);
    const invoiceId = useReactiveVar(invoiceIdVar);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const guestInvoicePrintPageComponent: JSX.Element = isLoaded ? (
        <OrderDetailsPageContent>
            <InvoicesPrint config={config} order={currentOrder} invoiceId={invoiceId} />
        </OrderDetailsPageContent>
    ) : (<></>);

    useEffect(() => {
        if (!salesGuestValidOrderResult) {
            return;
        }

        const { result, redirectUrl } = salesGuestValidOrderResult;
        const isRedirect = !result && !!redirectUrl.length;

        if (isRedirect) {
            navigateToUrl({
                navigate,
                url: redirectUrl,
            });
            return;
        }

        setIsLoaded(true);
    }, [salesGuestValidOrderResult]);

    useEffect(() => {
        if (!currentOrder) {
            return;
        }

        const {
            invoices,
        } = currentOrder;

        const updatedPageTitle = invoiceId !== ''
            ? t('Print Invoice #%1', (findInvoiceById(invoices, invoiceId)?.number || ''))
            : t('Print All Invoices');
        setPageTitle(updatedPageTitle);
    }, [currentOrder]);

    return (
        <SalesLayout
            pageKey="guest-invoice-print"
            pageTitle={pageTitle}
            pageContent={guestInvoicePrintPageComponent}
            salesLayoutClassName={orderDetailsStyles.printPageLayout}
            loadCart={false}
            loadCountries
            isGuestPage
            loadInvoices
            splitOrderItems={false}
        />
    );
};

export { InvoicePrintPage as default };
